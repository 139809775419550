@import "~csp-common-ui/dist/styles/color.scss";

.error-container {
    text-align: center; 
    height: 0px;     
    img {
        &.in-page-error{
            padding: 0px 0px 40px;
            width: 280px;
            height: 200px;
        }
    }
    .statement-start {
        padding: 16px 0px;
        a{
            color: $secondaryMain;
        }
    }
    .statement-end{
        padding: 60px 0px 32px;
        color: $primaryContrastText;
        a{
            color: $primaryContrastText;
        }
    }    
}
