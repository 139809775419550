@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

.contact-user-settings {
    box-shadow: $box-shadow-8; 
    background-color: $infoLightBg;
    margin-bottom: 20px; 
    .alert {
        height: 48px;
        width: auto;
    }

    .information-icon {
        color: $infoMain;
    } 

    .alert-message {
        padding: 2px;
    }
    
    .close-icon {
        position: relative;
        top: 3px;
        color: $actionActive;
        font-size: 24px;
    } 
    .contact-link{
        color: $textSecondary;
    }        
}


.user-settings {
    padding: 16px 32px 24px 32px;
    @include xs {
        padding: 0px;
    }
}

.user-profile-page {
    .avatar-icon {
        width: 120px;
        height: 120px;
        background: $actionActive;
        border-radius: 50%;
    }
    .border-box {
        box-shadow: 0px -0.5px 0px 0px rgba(96, 125, 139, 0.23) inset;
    }

    .user-setting-details {
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;  
        box-shadow: 0px -0.5px 0px 0px rgba(96, 125, 139, 0.23) inset;
        &.user-setting-booking-type {
            box-shadow: none;
        }    
    }
    .user-details {
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .edit {
        .user-setting-details {
            @include xs {
                height: 80px;
                flex-direction: column;
                align-items: stretch;
                justify-content: center;
                padding: 8px;
            }
        }
    }
    .secondary {
        color: $textSecondary;
    }
}