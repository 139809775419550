@import "~csp-common-ui/dist/styles/color.scss";

.modal-popup{
    background: linear-gradient(180deg, rgba(61, 69, 72, 0.8) 0%, rgba(55, 71, 79, 0) 100%);
}

.popUpBlock{
    color: $infoContrastText;
    .header-left-icons{
        float: left;
        margin: 24px 0px;
        .svg-connect-logo{
            position: relative;
            top: 4px;
            padding: 0px 24px;
            color: $successMain;
        }
    }
    .header-right-icons{
        float: right;       
        .order-icon{
            color: $infoContrastText;         
        }
    }
}
