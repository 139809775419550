body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src:
   url("./assets/fonts/Inter/inter-v3-latin-500.woff") format("woff"),
   url("./assets/fonts/Inter/inter-v3-latin-500.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src:
    url("./assets/fonts/Inter/inter-v3-latin-regular.woff") format("woff"),
    url("./assets/fonts/Inter/inter-v3-latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src:
    url("./assets/fonts/Inter/inter-v3-latin-600.woff") format("woff"),
    url("./assets/fonts/Inter/inter-v3-latin-600.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src:
    url("./assets/fonts/Poppins/poppins-v15-latin-600.woff") format("woff"),
    url("./assets/fonts/Poppins/poppins-v15-latin-600.woff2") format("woff2");
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src:
    url("./assets/fonts/Poppins/Poppins-Regular.woff") format("woff"),
    url("./assets/fonts/Poppins/Poppins-Regular.woff2") format("woff2");
}