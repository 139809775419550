@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

.switch-account-type {
    background-image: url('../../assets/warehousing.svg'), url('../../assets/Vector_bg.png');
    background-position: bottom -30px right -30px, left top 150px;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, 200px 400px;
    min-height: 100vh;
    .switch-account-section {
        margin-top: 24px;

        .title-cont {
            display: flex;
            justify-content: center;
            align-items: center;
            @include xs() {
                flex-direction: column;
            }
            @include sm() {
                flex-direction: column;
            }

            // .title-text {
            // }

            .title-back-desk {
                margin-right: auto;
                @include xs() {
                    display: none;
                }
                @include sm() {
                    display: none;
                }
                @include lg() {
                    left: 80px;
                }
            }

            .title-back-mob {
                display: none;
                margin-top: 12px;

                @include xs() {
                    display: flex;
                    justify-content: center;
                }
                @include sm() {
                    display: flex;
                    justify-content: center;
                }
            }
            .sub-title {
                margin-top: 16px;
            }

            .title-text {
                @include md() {
                    margin-left: -85px;
                    margin-right: auto;
                }
                @include lg() {
                    margin-left: -85px;
                    margin-right: auto;
                }
                @include xl() {
                    margin-left: -85px;
                    margin-right: auto;
                }
            }
        }
        
        .selection-boxes {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 48px;

            @include xs() {
                margin-top: 32px;
            }

            .switch-account-grid-item {
                @include xs() {
                    padding: 8px 12px !important;
                }
            }

            .account-cont {
                justify-content: center;
                @include md() {
                    justify-content: flex-start;
                    width: 930px;
                }
                @include sm() {
                    justify-content: flex-start;
                    width: 620px;
                }
                @include xs() {
                    justify-content: flex-start;
                    width: 310px;
                }
                &.more-items {
                    @include lg() {
                        justify-content: flex-start !important;
                    }
                    @include xl() {
                        justify-content: flex-start !important;
                    }
                }
            }

            @include xs() {
                flex-direction: column;
                
            }
            @include sm() {
                flex-direction: column;
            }

            .selection {
                width: 286px;
                height: 208px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                //padding: 32px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                //border: 2px solid #FFFFFF;

                .title-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    .icon-box {
                        width: 40px;
                        height: 40px;
                        align-items: center;
                        border-radius: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 19px;
                    }
                    .icon-box-dom {
                        background: $primaryMain;
                    }
                    .icon-box-int {
                        background: $infoLight;
                    }
                    .icon-box-ware {
                        background: #FF9800;
                    }
                    .icon-box-dis {
                        background: #CFD8DC;
                    }
                }

                .account-name {
                    display: flex;
                    justify-content: center;
                    margin-top: 12px;
                }
                .account-number {
                    display: flex;
                    justify-content: center;
                    padding-top: 4px;
                    
                }
                .action {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 24px;
                }
                .slide-up-fade-in {
                    -webkit-animation: slideUpFadeIn ease 1s;
                    animation: slideUpFadeIn ease 1s;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                    -webkit-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
                    -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards;
                    opacity: 0;
                    opacity: 1\9;
                }
                
                .animation-delay-slow {
                    -webkit-animation-delay: 0.15s;
                    animation-delay: 0.15s;
                }
                
                .animation-delay-slower {
                    -webkit-animation-delay: 0.3s;
                    animation-delay: 0.3s;
                }
                
                .animation-delay-slowest {
                    -webkit-animation-delay: 0.5s;
                    animation-delay: 0.5s;
                }
                
                .animation-delay-turtle {
                    -webkit-animation-delay: 0.85s;
                    animation-delay:  0.85s;
                }
                
                
                
                @-webkit-keyframes slideUpFadeIn {
                    0% {
                        opacity: 0;
                        -webkit-transform: translate(0px, 40px);
                        transform: translate(0px, 40px);
                        -webkit-filter: blur(0);
                        filter: blur(0);
                    }
                
                    100% {
                        opacity: 1;
                        -webkit-transform: translate(0px, 0px);
                        transform: translate(0px, 0px);
                        -webkit-filter: blur(0px);
                        filter: blur(0px);
                    }
                }
                
                @keyframes slideUpFadeIn {
                    0% {
                        opacity: 0;
                        -webkit-transform: translate(0px, 40px);
                        transform: translate(0px, 40px);
                        -webkit-filter: blur(0px);
                        filter: blur(0px);
                    }
                
                    100% {
                        opacity: 1;
                        -webkit-transform: translate(0px, 0px);
                        transform: translate(0px, 0px);
                        -webkit-filter: blur(0px);
                        filter: blur(0px);
                    }
                }
                &:hover:not(.disabled) {
                    background: $primaryLightBg;
                    //border: 2px solid $primaryMain;
                    cursor: pointer;
                    scale: 1.02;
                    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.08);
                    button {
                        background-color: $primaryMain;
                        color: $primaryContrastText;
                        border: 1px solid $primaryMain;
                    }
                }
            }
            .selection-dom {
                border-top: 8px solid $primaryMain;
            }
            .selection-int {
                border-top: 8px solid $infoLight;
            }
            .selection-ware {
                border-top: 8px solid #FF9800;
            }
            .selection-dis {
                border-top: 8px solid #CFD8DC;
            }
        }
        
    }
}