@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

.help-drawer-popup {
    
    .header-drawer {
        height: 64px;
        .icon {
            font-size: 28px;
            color: $secondaryMain;
        }
    }
    .drawer-info {
        height: 130px;
        .contact-number {
            a {
                text-decoration: none;
                color: $secondaryMain;
            }
        }
    }
    
    .feedback-section {
        .training-button {
            opacity: 0;
            text-transform: none;
            width: 197px;
            height: 32px;  
            text-align: center;
            padding: 6px 12px 6px 16px;
            margin : 8px 0px ;  

            .iconexternal{
                font-size: 20px;     
                margin-left:5px ;              
            }           
        }               
                
    }
}