@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";


.limited-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;

    .logo {
        width: 148px;
    }

    .header-right {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .profile-name {
            padding-right: 8px;
            padding-left: 8px;

            @include xs() {
                display: none;
            }
        }

        .avatar-icon {
            background-color: #3d4548;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            border: 2px solid transparent;
        }

        .help-button {
            background-color: #fff;
            border-radius: 8px;
            width: 44px;
            height: 44px;
            margin-left: 8px;
        }
    }
    

}

