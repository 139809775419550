@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";
@import "~csp-common-ui/dist/styles/z-index.scss";

.terms-main {
    height: 100vh;
    background-color: $secondaryContrastText;
    display: flex;
    align-items: center;
    justify-content: center;

    .terms-change-alert {
        .alert-icon {
            color: white;
            font-size: 20px;
        }

        &.page {
            position: fixed;
            z-index: $bannerZIndex;
            width: 100%;
            top: 0px;
            left: 0px;
        }
    }

    .terms-grid {
        background: $secondaryContrastText;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 16px 32px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding-bottom: 18px;
        
        .terms-header {
            padding-left: 40px;
            padding-right: 28px;
            padding-top: 12px;
            padding-bottom: 10px;
            color: $primaryDark;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .terms-content {
            .content {
                padding-left: 40px;
                padding-right: 28px;
                padding-top: 12px;
                padding-bottom: 4px;
                height: 50vh;
                max-height: 400px;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 6px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #cfd8dc;
                    border-radius: 50px;
                }
            }
        }
        .terms-footer {
            padding-left: 18px;
            padding-right: 18px;
            padding-top: 28px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            
            button {
                margin-left: 15px;
                text-transform: none;
            }
        }
        .termsAgreedError {
            i {
                color: $errorMain;
                padding-right: 5px;
                position: relative;
                top: 2px;
            }
            padding-top: 0px !important;
            @include sm() {
                padding: 45px 10px 40px 40px;
            }
            @include xs() {
                padding: 45px 10px 40px 40px;
            }
        }
        .termsAgreed {
            @include sm() {
                padding: 45px 10px 10px 40px;
            }
            @include xs() {
                padding: 45px 10px 10px 40px;
            }
            .check-message {
                color: $errorMain;
            }
            #agree {
                margin-right: 10px;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                appearance: none;
                font-size: 0.8em;
                text-align: center;
                line-height: 1em;
                background: $successMain;
                border: 1px solid $successTextDark;
                height: 15px;
                width: 15px;
                &:checked {
                    &:after {
                        content: "✔";
                        color: $secondaryContrastText;
                    }
                }
            }
        }
    }
}
