@import "~csp-common-ui/dist/styles/color.scss";
.tooltip-wrapper {
  display: inline-block;
  position: relative;
  .tooltip-tip {
    position: absolute;
    padding: 4px 16px;
    border-radius: 4px;
    box-shadow: $box-shadow-16;
    z-index: 100;
    white-space: nowrap;
    &.dark {
      background-color: $secondaryMain;
      color: $infoContrastText;
    }
    &.light {
      background-color: $infoContrastText;
      color: $textSecondary;
    }
  }
}
  