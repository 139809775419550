@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

.switch-service-type {
    .switch-service-section {
        margin-top: 24px;

        .sub-title {
            margin-top: 16px;
        }
        .selection-boxes {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 48px;
            gap: 32px;

            @include xs() {
                flex-direction: column;
                
            }
            @include sm() {
                flex-direction: column;
            }

            .selection {
                width: 320px;
                height: 270px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                padding: 32px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border: 2px solid #FFFFFF;

                .title-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .image-cntr {
                    display: flex;
                    justify-content: center;
                    padding-top: 24px;
                    padding-bottom: 24px;

                    img {
                        width: 233px;
                    }
                }
                .action {
                    display: flex;
                    justify-content: center;
                }

                &:hover:not(.disabled) {
                    background: $primaryLightBg;
                    border: 2px solid $primaryMain;

                    button {
                        background-color: $primaryMain;
                        color: $primaryContrastText;
                        border: 1px solid $primaryMain;
                    }
                }
            }
        }
    }
}