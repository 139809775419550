@import "~csp-common-ui/dist/styles/color.scss";

.sec-padding {
  padding: 0 6px;
}

.outage-banner-container {
  height: 56px;
  background-color: $infoMain;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 16px 2px 16px;
  width: 100%;
  box-sizing: border-box;

  .info-content {
    color: $infoContrastText;
    & > * {
      @extend .sec-padding;
    }
    i {
      font-size: 20px;
    }
  }

  .close-btn {
    color: $infoContrastText;
    opacity: 0.54;
    font-size: 20px;
  }
}
