@import "~csp-common-ui/dist/styles/color.scss";
@import "../../styles/custom.scss";


.maintenance-page {
    text-align: center;
    img {
        &.primary-connect-logo {
            height: $primaryConnectLogoHeight;
            // width: $primaryConnectLogoWidth;
            padding: 32px 0px;
        }
        &.primary-connect-truck {
            height: $maintenanceHeight;
            width: $iconWidth;
            padding: 64px 0px 24px;
        }
    }
    .maintenance-msg {
        padding: 16px 0px;
    }
    .maintenance-contact {
        padding: 64px 0px;
        color: $textSecondary;
        a {
            color: $textSecondary;
        }
    }
}

