@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";


.impersonate-user-container {
    .title-box {
        box-shadow: 0px 0.5px 0px 0px #607D8B3B inset;;
    }
    .error-button {
        background-color: $errorMain;
        color: white;

        &:hover {
            background-color: $errorMain;
        }
    }
}

.stop-impersonate-confirm {
    .alert-dialog-title {
        box-shadow: inset 0px -0.5px 0px #607D8B3B;
        
    }
    .impersonation-confirm-cancel, .impersonation-confirm-ok {
        width: 160px;
    }
}

.sec-padding {
    padding: 0 6px;
}
 

.impersonation-header-container {
    height: 68px;
    background-color: $primaryMain;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 32px 2px 32px;
    width: 100%;
    box-sizing: border-box;

    @include xs() {
        padding: 2px 16px 2px 16px;
    }

    @include sm() {
        padding: 2px 16px 2px 16px;
    }
    @include md() {
        padding: 2px 16px 2px 16px;
    }

    .imp-user-icon {
        @extend .sec-padding;
        i {
            font-size: 20px;
        }
    }
    .imp-title {
        @extend .sec-padding;
        @include xs() {
            display: none;
        }
        @include sm() {
            display: none;
        }

    }
    .imp-helper-icon {
        @extend .sec-padding;
        i {
            font-size: 20px;
        }

        @include xs() {
            display: none;
        }
    }
    .imp-pipe {
        @extend .sec-padding;
    }
    .imp-avatar {
        .avatar-button {
            position: relative;
            &:hover {
                background-color: transparent;
            }
            .avatar-icon {
                background-color: $secondaryMain;
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 100%;
                border: 2px solid transparent;
                &:hover {
                    border: 2px solid $primaryMain;
                }
            }
        }
        
    }
    .imp-profile-name {
        top: 10px;
        display: flex;
        align-items: center;

        @include xs() {
            .name {
                font-size: 14px;
            }
            .company {
                font-size: 12px;
            }
        }
    }
     
}
