@import "~csp-common-ui/dist/styles/color.scss";
.user-feedback-page{
    padding: 24px 32px;
    .rating{
        .co-MuiRating-iconEmpty{
            color: transparent;
            opacity: 0;
        }
        .co-MuiRating-iconHover{   
            color: #FFC400;
            opacity: 0.7;     
        
        }
    }
    .feedback-comment{
        white-space: pre-wrap;
    }
    .sortActive{
        color: black;
        text-decoration: none;
         width:  24px;
         height: 24px;
         margin: 8px;
         cursor: pointer;
          &:hover{
           border-radius: 8px;
           background: $actionHover;        
           }
       }
       .sortInActive{
        color: gray;
        text-decoration: none;
         width:  24px;
         height: 24px;
         margin: 8px;
        cursor: pointer;
         &:hover{
         color: black; 
         border-radius: 8px;
         background: $actionHover;    
         }
       }    
}
.download-feedback-modal {
    border-radius: 8px;
    .content {
        box-shadow: 0px 0.5px 0px 0px #607D8B3B inset;
        padding: 24px;

        .print-option {
            align-items: center;
            display: flex;
            flex-direction: row;
            .disabledText {
              color: $textDisabled;
            }
        }
    }
}