@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

@mixin mobile() {
    @include xs() {
        @content;
    }
    @include sm() {
        @content;
    }
}

.dashboard-landing-main{
    // padding-right: 12px !important;
    .dashboard-quick-action-text{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 133.33%;
        color: $primaryContrastText;
        margin-top: 15px;
        margin-bottom: 15px;
        justify-content: space-between;
        .pencil-icon {
             color: $textPrimary;
        }
    }

    .dashboard-quick-action-buttons{
        height: 88px;
    }

    .dashboard-activity-overview-buttons{
        height: 112px;
    }



    .dashboard-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin:13px 10px 13px 10px;
        background: $secondaryContrastText;
        box-shadow: $box-shadow-4;
        border-radius: 8px;
        cursor: pointer;

        @include md() {
            margin:9px 10px 9px 10px;
        }
        @include sm() {
            margin:9px 10px 9px 10px;
        }
        @include xs() {
            margin:9px 10px 9px 10px;
        }

        &:hover{
           box-shadow: $box-shadow-8;
        }


        &.dashboard-add-button{
            align-items: center;
            justify-content:flex-start;
            height: 88px;
            background-color: $primaryLightBg;
            i{
                padding: 24px;
                padding-right: 16px;
                color: $primaryMain;
            }

            &.activity-add-button {
                height: 112px;
            }
        }

        .dashboard-card-desc {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 137.5%;
            color: $primaryContrastText;

            @include md() {
                font-size: 14px;
            }
            @include sm() {
                font-size: 14px;
            }
            @include xs() {
                font-size: 14px;
            }

            &.dashboard-card-date-range {
                font-weight: bold;
            }
        }

        .dashboard-card-number{
            margin: 5px 0;
            text-align: left;
            font-size: 24px;
            color: $primaryContrastText;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
        }

        .dashboard-arrow-image{
            margin-left: auto;
            font-size: 23px;
            &.dashboard-arrow-inactive{
                color: $textDisabled !important;
            }
        }

        .dashboard-card-button {
            width: 100%;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
        }

        .dashboard-card-button-type-b {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            width: 100%;
            justify-content: space-between;
            height: 100%;
            box-sizing: border-box;
            padding: 16px;
        }
    }
    .success-alert{
        margin-top: 24px;
    }

    .loading-text {
        background: linear-gradient(96.81deg, #F4F4F4 12.62%, #FFFFFF 95.23%);
        border-radius: 5px;
    }
    .loading-text-circle {
        background: linear-gradient(96.81deg, #F4F4F4 12.62%, #FFFFFF 95.23%);
        border-radius: 50%;
    }

    .customer-dashboard {
        .dashboard-widget-box-shadow {
            box-shadow: 0px 0px 1px 0px #00000014;
            box-shadow: 0px 2px 4px 0px #0000001A;
        }

        .dashboard-daily-summary-widget {
            .header {
                height: 56px;
                box-sizing: border-box;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 14px;
                padding-bottom: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
                .month-short {
                    display: none;
                }
            }

            .daily-summary-data-body {
                .daily-summary-slider {
                    box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
                    .date-picker-box {
                        height: 54px;
                        width: 60px;
                        border-radius: 8px;
                        padding: 8px 12px 8px 12px;
                        border: 0.5px solid #3D454840;
                        margin-left: 4px;
                        margin-right: 4px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        box-sizing: border-box;
        
                        .text-item {
                            user-select: none;
                        }
        
                        .dateText {
                            color: $textSecondary;
                        }
                        &.selected {
                            background-color: $primaryMain;
                            border: 1px solid $primaryMain;
                            box-shadow: 0 0 1px 0.5px $primaryMain;
        
                            .dateText {
                                color: $textPrimary !important;
                            }
                        }

                        &.today {
                            border: 1px solid $primaryMain;
                            box-shadow: 0 0 1px 0.5px $primaryMain;
                        }
                    }
        
                    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
                        display: none;
                    }
                    
                    .react-horizontal-scrolling-menu--scroll-container {
                        -ms-overflow-style: none; /* IE and Edge */
                        scrollbar-width: none; /* Firefox */
                    }
                }
                
                .daily-summary-result {
                    overflow: hidden;
                    .summary-list {
                        margin-right: -1px;
                        width: calc(100% + 2px);
                        margin-bottom: -1px;
                        // box-shadow: $box-shadow-4;
                        .grid-column {
                            border-right: 0.5px solid #0000001F;
                            border-bottom:  0.5px solid #0000001F;
                            overflow: hidden;
                            
                            .daily-summary-link {
                                text-decoration: none;
                                display: flex;
                                min-height: 130px;
                                align-items: center;
                                height: 100%;

                                .tile-box {
                                    position: relative;
                                    flex-grow: 1;
                                    height: 100%;

                                    .content {
                                        flex-grow: 1;

                                        .alert-icon {
                                            color: $warningMain;
                                            font-size: 14px;
                                            padding-right: 8px;
                                        }
                                    }

                                    .icon-box {
                                        width: 160px;
                                        margin-right: -35px;
                                        margin-bottom: -50px;
                                        overflow: hidden;
                                        display: flex;
                                        align-self: flex-end;
                                        position: absolute;
                                        right: -10px;

                                        &.margin-b-45 {
                                            margin-bottom: -45px;

                                        }

                                        &.margin-b-40 {
                                            margin-bottom: -40px;

                                        }

                                        &.margin-b-30 {
                                            margin-bottom: -30px;

                                        }
            
                                        .main-icon {
                                            font-size: 160px;
                                            color: $secondaryLight;
                                            opacity: 0.05;
                                        }
            
                                        .sub-icon {
                                            font-size: 70px;
                                            margin-left: -130px;
                                            align-self: center;
                                            color: white;
                                            opacity: 11.05;
                                            z-index: 1;
                                            font-weight: 800;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
            @include mobile() {
                .month-full {
                    display: none !important;
                }
                .month-short {
                    display: block !important;
                }
            }

            .half-size {
                .month-full {
                    display: none !important;
                }
                .month-short {
                    display: block !important;
                }
            }
        }

        .dashboard-status-widget {
            .header {
                height: 56px;
                box-sizing: border-box;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 14px;
                padding-bottom: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
            }
            .status-data-body {
                .status-data-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .status-graph {
                        width: 288px;
                        height: 288px;
                    }

                    .status-data {
                        width: calc(100% - 288px);
                        background-color: $grey-50;
                        align-self: stretch;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;

                        .status-item-container, .status-item-container-small {
                            padding: 8px;
                            display: flex;
                            align-items: center;
                            .status-item-link {
                                text-decoration: none;
                                .status-item-box-style {
                                    min-height: 80px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 16px;
                                    margin: 12px;
                                    box-shadow: 0px 2px 4px 0px #0000001A;
                                    cursor: pointer;
                                    border-radius: 8px;
                                    text-decoration: none;

                                    .status-count {
                                        width: 35px !important;
                                        height: 35px !important;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-right: 16px;
                                        flex-basis: 35px;
                                        flex-shrink: 0;
                                    }
                                    .description {
                                        line-height: 1;
                                        text-decoration: none;
                                        border-bottom: 1px solid $textPrimary;
                                    }
                                }
                                .status-item-link-style {
                                    display: flex;
                                    align-items: center;
                                    padding: 8px;
                                    cursor: pointer;
                                    text-decoration: none;

                                    .status-count {
                                        width: 35px !important;
                                        height: 35px !important;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-right: 16px;
                                        flex-basis: 35px;
                                        flex-shrink: 0;
                                        box-sizing: border-box;
                                    }
                                    .title {
                                        line-height: 1;
                                        text-decoration: none;
                                        border-bottom: 1px solid $textPrimary;
                                    }
                                }
                            }

                        }

                        .status-item-container-small {
                            display: none;
                            align-self: center;
                            align-items: flex-start;
                        }
                    }
                }
                
                .alert-banner {
                    display: none;
                }
            }
            @include mobile() {
                .alert-web {
                    display: none !important;
                }
                .alert-banner {
                    display: flex !important;
                }
                .status-data-container {
                    flex-direction: column;
                    
                    .status-data {
                        width: 100% !important;
                        background-color: white !important;
                        display: flex;
                    }
                    
                    .status-item-container {
                        display: none !important;
                    }
                    .status-item-container-small {
                        display: flex !important;
                    }
                }
            }
            &.half-size {
                .alert-web {
                    display: none !important;
                }
                .alert-banner {
                    display: flex !important;
                }

                .status-data-container {
                    
                    .status-data {
                        background-color: white;
                        display: flex;
                    }
                    
                    .status-item-container {
                        display: none !important;
                    }
                    .status-item-container-small {
                        display: flex !important;
                    }
                }
                .status-graph {
                    width: 240px !important;
                    height: 240px !important;
                }
            }
        }

        .dashboard-quick-actions-widget {
            .header {
                height: 56px;
                box-sizing: border-box;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 14px;
                padding-bottom: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
            }

            .dashboard-quick-actions-widget-cntr {
                // grid-gap: .5px;
                // background-color: #607D8B3B;
                margin-right: -1px;
                width: calc(100% + 1px);
                margin-bottom: -1px;
                // box-shadow: $box-shadow-4;
                .grid-item {
                    border-right: 0.5px solid #0000001F;
                    border-bottom:  0.5px solid #0000001F;
                    height: 119px;
                    align-items: center;
                    display: flex;
                }
                .dashboard-button {
                    margin: 0px !important;
                    border-radius: 0px !important;
                    box-shadow: none !important;
                    width: 100%;

                    .dashboard-card-desc {
                        font-size: 14px !important;
                    }

                    &.dashboard-add-button{
                        height: 119px !important;
                    }
                }
            }
        }
        .dashboard-exception-widget {
            .header {
                height: 56px;
                box-sizing: border-box;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 14px;
                padding-bottom: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
            }
            .exception-data-body {
                .exception-data-container {
                    overflow: hidden;
                    .grid-container {
                        width: calc(100% + 2px);
                        margin: -1px;
                        
                        .grid-item {
                            height: 142px;
                            box-sizing: border-box;
                            border-right: 0.5px solid #0000001F;
                            border-bottom:  0.5px solid #0000001F;
                            .excepiton-link {
                                text-decoration: none;
                                .exception-alert-icon {
                                    width: 58px;
                                    display: flex;
                                    .reverse-truck {
                                        transform: scaleX(-1);
                                        display: flex;
                                    }
                                    .main-icon {
                                        font-size: 60px;
                                        color: $actionActive;
                                    }
                                    .sub-icon {
                                        font-size: 24px;
                                        color: $warningMain;
                                        align-self: flex-end;
                                        margin-left: -24px;
                                        text-shadow: 2px 2px 0 white,
                                            -2px -2px 0 white,
                                            2px -2px 0 white,
                                            -2px 2px 0 white,
                                            0px 2px 0 white,
                                            4px 0px 0 white,
                                            0px -4px 0 white,
                                            -4px 0px 0 white;
                                        z-index: 1;
                                    }
                                }
                                .exception-alert-icon-empty {
                                    width: 58px;
                                    display: flex;
                                    i {
                                        font-size: 60px;
                                    }
                                }
                            }
                        }
                    }
                }
                .alert-banner {
                    display: none ;
                }
            }
            @include mobile() {
                .alert-web {
                    display: none !important;
                }
                .alert-banner {
                    display: flex !important;
                }
                
            }
            &.half-size {
                .alert-web {
                    display: none !important;
                }
                .alert-banner {
                    display: flex !important;
                }
            }
        }
        .dashboard-quick-glance-widget {
            .header {
                height: 56px;
                box-sizing: border-box;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 14px;
                padding-bottom: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
            }
            .quick-glance-body {
                .order-list-item {
                    box-sizing: border-box;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
                    padding-left: 16px;
                    padding-right: 16px;
                    padding-top: 4px;
                    padding-bottom: 4px;

                    @include xs() {
                        height: 76px !important;
                    }

                    .po-link {
                        width: 100%;
                        padding-right: 8px;
                        color: $textPrimary;;
                    }

                    .status-chip {
                        @include xs() {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                    .grid-item {
                        display: flex;
                        align-items: center;

                    }
                }
            }
        }
        
    }
}

.feed-back-section-dashboard {
    .feedback-button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin:13px 20px 0px 0px;
        background: $secondaryContrastText;
        box-shadow: $box-shadow-4;
        border-radius: 8px;
        align-items: flex-start;
        padding: 18px;

        @include md() {
            margin:9px 20px 9px 0;
        }
        @include sm() {
            margin:9px 20px 9px 0;
        }
        @include xs() {
            margin:9px 20px 9px 0;
            padding:14px;
        }
        .view-feedback-button{
            width: 100%;
            padding-top: 16px;
            .view-feedback {
                background-color: $primaryMain;
                .view-feedback-link {
                    color: $primaryContrastText;
                    text-decoration: none;
                    text-transform: none;
                }
            }
        }

        .rating{
            position:relative;
            padding-top:16px;
            .co-MuiRating-iconHover{
                color: #FFC400;
                opacity: 0.7;

            }
        }
        .textarea-lable{
            padding-top: 24px;
        }
        .text-area{
        margin-top: 8px;
            .Mui-focused {
                fieldset {
                    border: 1px solid $secondaryLight;
                }
            }
            .co-MuiFormHelperText-contained{
                margin-left: 0px;
            }
        }


        .feedBackButton{
            width: 161px;
            height: 44px;
            border-radius: 4px;
            margin: 24px 24px 0px 0px;
            padding: 10px, 22px, 10px, 22px;
            text-align: center;
            text-transform: none;
        }
        .info-buttonicon {
            color: rgba(38, 50, 56, 0.54);
            padding: 0px;
            margin: 0px 0px 2px 4px;
        }


    }
    .info-tooltip{
        width: 284px;
        border-radius: 4px;
    }
    .toggle-button{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin:13px 20px 32px 0px;
        background: $secondaryContrastText;
        border-radius: 8px;
        padding: 16px;
        justify-content: space-between;
    .toggle-box{
            color: #90C928;
            padding-right: 10px;
            font-size: 30px;
        }
        .feedback {
            color: $textPrimary;
            padding: 2px;
        }
    }
    .box-button {
        padding: 10px;
        margin-right: 12px;
    }
}

.add-dialog-box{
    margin: auto 0;
    position: relative;

    .dialog-alert{
        align-items: center;
        background-color: #EBF8FC;
        i{
            color: #007FA8;
        }
    }
    .dialog-main-title {
        padding: 24px 24px;
        box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
    }

    .dialog-title{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .dialog-content{
        width: 552px;
    }
    .dialog-switch{
        padding: 8px 0 0px 0;
        display: flex;
        justify-content: space-between;
    }
    .dialog-buttons{
        padding: 12px 0 12px 0;
        float: right;
    }
    .pod-modal-title {
        padding: 24px;
        box-shadow: 0px -0.5px 0px 0px rgba(96, 125, 139, 0.23) inset;
    }
    .pod-modal-content {
        padding: 24px;
        box-shadow: 0px -0.5px 0px 0px rgba(96, 125, 139, 0.23) inset;
        .auto-complete-tag-list {
            max-height: 300px;
            overflow-y: auto;
    
            &::-webkit-scrollbar-track {
                box-shadow: inset 0.5px 0px 0px rgba(96, 125, 139, 0.23);
            }
            &::-webkit-scrollbar {
                width: 14px;
                height: 14px;
                background-color: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $actionActive;
                border-radius: 9999px;
                border: 4px solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
            }
        }
        .end-adornment-contr {
            right : 10px !important;
        }
        .search-autocomplete-input-root {
            padding-right: 0px !important;
        }
        .search-autocomplete-input {
            &.hide-input {
                display: none;
            }
        }
        .co-MuiAutocomplete-input {
            flex-grow: 0;
        }
        .auto-complete-chip {
            margin-left: 0px;
            margin-right: 8px;
            margin-bottom: 4px;
            margin-top: 2px;
            max-width: 100%;
    
            &.unmatched {
                border: 1px solid $errorLight;
                background-color: $errorLightBg;
                color: $errorTextDark;
    
                .MuiChip-deleteIcon {
                    color: $errorMain;
                }
            }
        }
        .autocomplete-chip {
            .MuiFormHelperText-marginDense {
                margin-left: 14px;
            }
            .MuiInput-inputMarginDense {
                padding-top: 10.5px;
                padding-bottom: 10.5px;
            }
            .Mui-focused {
                fieldset {
                    border: 1px solid $secondaryLight !important;
                }
            }
            .Mui-error.Mui-focused {
                fieldset {
                    border: 1px solid $errorMain !important;
                }
            }
            .MuiInputBase-root, .MuiOutlinedInput-multiline  {
                background-color: white;
                &:not(.Mui-error) {
                    &:hover {
                        fieldset{
                            outline:none;
                            border-color: $secondaryLight !important;
                            box-shadow:0 0 3px $secondaryLight !important;
                        }
                    }
                    &:focus-within {
                        fieldset{
                            outline:none;
                            border-color:$secondaryLight !important;
                            box-shadow:0 0 3px $secondaryLight !important; 
                        }
                    }
                }
            }
        }
        .no-pod-message {
            margin-top: 16px;
        }
        .po-box {
            margin-top: 16px;
            border: solid 0.5px #DAE1E4;
            border-radius: 4px;
            .po-table {
                display: flex;
                justify-content: space-between;
                padding: 4px 16px;
                border-bottom: solid 0.5px #DAE1E4;
                .po-details-link {
                    color: $textPrimary;
                }
            }
            .po-not-found {
                color: $errorMain;
                background-color: $errorLightBg;
            }
            .po-table-footer {
                display: flex;
                .po-table-footer-row {
                    display: flex;
                    justify-content: space-between;
                    flex: auto;
                    .download-po-btn {
                        padding: 6px 16px;
                        .ri-download-line {
                            font-size: 16px;
                            margin-right: 8px;
                        }
                    }
                    .po-table-pagination {
                        border-bottom: none;
                    }
                }
            }
        }
        .info-alert-box {
            box-shadow: $box-shadow-8; 
            background-color: $infoLightBg;
            .alert {
                width: auto;
                display: flex;
            }
            .information-icon {
                color: $infoMain;
            } 
            .alert-message {
                padding: 2px;
                margin-bottom: 6px;
            }
        }
        .success-alert-box {
            box-shadow: $box-shadow-8; 
            background-color: $successLightBg;
            .alert {
                width: auto;
                display: flex;
                border: 1px solid $successMain;
            }
            .success-icon {
                color: $successDark;
            } 
            .success-message {
                padding: 2px 0;
            }
        }
        .archived-pod-message {
            padding: 2px;
            .archived-pod-link {
                white-space: nowrap;
                color: $textPrimary;
            }
        }
    }
    .pod-modal-actions {
        padding: 24px;
        justify-content: space-between;
        .pod-modal-actions-link {
            color: $primaryTextDark;
        }
    }
}

.favourite-dialog-box {

    .favourite-title{
        .star-color {
            color: $warningLight;
        }
        .favourite-order {
             margin: 0px 0px 0px 16px;
        }
    }
    .fav-margin-default{
        .tab-title{
            margin-left: -16px;
        }
    }

    .favourite-content-default {
        width: 528px;
        max-height: calc(100vh - 274px);
        padding-left: 36px;
        padding-right: 28px;
        min-height: 280px;
        margin-bottom: -2px;
        margin-top: -16px;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 16px;
            height: 16px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #CFD8DC;
            border-radius: 9999px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
        .star-color {
            color: $warningLight;
        }
        a {
            text-decoration: none;
            color: #3D4548;
            p{
                text-decoration: underline;
            }
        }
    }

    .tab-title-container {
        margin-left: 32px;
        margin-right: 32px;
    }

    .favourite-modal {
        border-bottom: 1px solid $grey-A300;
    }
    .favourtie-modal-single {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .see-all-default {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 16px;

        .arrow-right{
            color: rgba(38, 50, 56, 0.54);
            padding-left: 8px;
            font-size: 18px;
            height: 32px;
            margin-top: -2px;
        }
    }

    .no-data-fav-modal-wrapper {
        display: flex;
        width: 577px;
        justify-content: center;
    }

}

.daily-summary-settings-modal {
    .title {
        padding: 16px 24px;
        box-shadow: 0px -0.5px 0px 0px #607D8B3B inset;
    }
}