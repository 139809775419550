@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";
@import "~csp-common-ui/dist/styles/z-index.scss";
@import "../../styles/custom.scss";

.navbar-container {
    position: fixed;
    height: 100%;
    background-color: $secondaryMain;
    z-index: $navBarZIndex;
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        &.navbar-large {
            height: 100%;
        }
        li {
            list-style: none;
            height: $listItemHeight;
            width: $listItemWidth;
            align-items: center;
            justify-content: center;
            display: flex;
            cursor: pointer;
            color: white;
            font-size: 20px;
            img {
                &.primary-connect-logo {
                    height: $logoHeight;
                    width: $logoWidth;
                }
            }
            &.logo {
                border-bottom: 1px solid $lightBorderColor;
                box-sizing: border-box;
            }
            &.nav-submenu {
                position: relative;
                &:after {
                    content: "";
                    border: 4px solid transparent;
                    border-left: 4px solid $secondaryContrastText;
                    position: absolute;
                    right: 10px;
                }
            }
        }
        a {
            text-decoration: none;
        }
    }
    ul {
        &.nav-submenu-container {
            display: flex;
            position: fixed;
            width: $subMenuWidth;
            height: auto;
            left: 0;
            margin-left: 64px;
            padding-left: 24px;
            justify-content: space-around;
            background-color: $textPrimary;
            color: $secondaryContrastText;
            border-radius: 0px 8px 8px 0px;
            li {
                width: 100%;
                justify-content: start;
                font-size: 16px;
                &:hover {
                    color: $primaryMain;
                }
            }
        }
    }
    .active-item {
        background-color: $textPrimary;
        color: $primaryMain;
        &.nav-submenu {
            &:after {
                border-top: 4px solid $primaryMain;
                border-left: 4px solid transparent;
                top: 30px;
            }
        }
        li {
            color: $primaryMain;
        }
    }
}

.navbar-expanded {
    .navbar-toggle-container {
        position: fixed;
        top: 24px;
        left: 24px;
        height: 44px;
        width: 44px;
        display: flex;
        background: $secondaryMain;
        border-radius: 8px;
        z-index: $navBarZIndex;
        button {
            flex: 1;
            border: 0 none;
            background-color: transparent;
            color: $secondaryContrastText;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 24px;
        }
    }
    .navbar-expanded-container-header {
        // height: 64px;
        padding: 24px 0;
        display: flex;
        position: relative;
        align-items: center;
        border-bottom: 1px solid $lightBorderColor;
        .nav-container-close {
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 22px;
            color: $secondaryContrastText;
            cursor: pointer;
            font-size: 20px;
        }
        a {
            // padding: 0 90px;
            width: 100%;
            text-align: center;
            img {
                width: 100%;
                max-width: 148px;
            }
        }
    }
    .navbar-container {
        @include xs() {
            width: 100%;
        }
        @include sm() {
            width: 300px;
        }
        @include xl() {
            width: 280px;
        }
        overflow: hidden;
        ul {
            li {
                width: 100%;
                justify-content: start;
            }
        }
        .tooltip-wrapper {
            display: flex;
            justify-content: center;
        }
        .nav-submenu-container {
            margin: 0;
            width: 100%;
            overflow: hidden;
            padding-left: 60px;
            max-height: 0px;
            transition: max-height 0.1s ease-out;
            position: relative;
            &.submenu-open {
                height: auto;
                max-height: 1000px;
                transition: max-height 0.2s ease-in;
            }
            .active-item {
                .layout-name {
                    color: $primaryMain;
                }
            }
        }
        .layout-icon {
            width: 20px;
            padding: 0 18px 0 22px;
        }
        li {
            &.nav-submenu {
                &:after {
                    border-top: 4px solid $secondaryContrastText;
                    border-left: 4px solid transparent;
                    top: 30px;
                    right: 28px;
                }
            }
        }
        .active-item {
            &.active-item-icon {
                &.nav-submenu {
                    &:after {
                        border-bottom: 4px solid $secondaryContrastText;
                        border-top: 4px solid transparent;
                        top: 25px;
                    }
                }
            }
            .layout-name {
                color: $secondaryContrastText;
            }
        }
    }
    
}