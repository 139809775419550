$primary-colour: #90C928;

.qrcode{
    border: 3px solid;
    border-radius: 8px ;
    border-color: $primary-colour;
    height: 100%;

    .bottom-box{
        background-color: $primary-colour;
        align-items: center;
        width: calc(100% + 2px);
        height: 20px;
        position: relative;
        right: 1px;
        top: 0.5px;
        div{
            position: relative;
            text-align: center;
        }

        .bottom-content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: row;
            i{
                padding-right: 1px;
            }
            h3{
                padding-top: 2.5px;
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 8px;
                line-height: 143%;
            }
                
        }
    }
}