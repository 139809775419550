@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";


.Contact-main{
    box-shadow: $box-shadow-8; 
    background-color: $infoLightBg;
    margin-bottom: 20px; 
    .alert {
        width: auto;
        display: flex;
        align-items: center;
    }

    .information-icon {
        color: $infoMain;
    } 

    .alert-message {
        padding: 2px;
    }
    
    .alert-close-icon {
        color: $actionActive;
        font-size: 24px;
        cursor: pointer;
        margin-top: -4px;
    } 
    
    .contact-link {
        border: none;
        color: $textPrimary;
        background-color: transparent;
        text-decoration: underline;
        font-size: 14px;
    }  
}
