@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

.global-search-popup {
    pointer-events: all;
    width: 525px;
    border-radius: 8px;
    top: 50px;
    box-shadow: 0 4px 10px rgba(125, 146, 161, 0.75);
    z-index: 998;

    @include xs(){
        width: calc(100% - 64px);
        margin: auto;
    }
    @include sm(){
        width: calc(100% - 64px);
        margin: auto;
    }


    .error-alert{
        color: #DB3632;
        background-color: none;
        .error-alert-icon{
            padding: 5px ;
        }
    }
    .global-search-table{
        background-color: white;
        overflow-y: overlay;
        overflow-x: hidden;
        border-top-left-radius: 8px;
        &::-webkit-scrollbar-track {
            box-shadow: inset 0.5px 0px 0px rgba(96, 125, 139, 0.23);
            background-color: white;
        }
        &::-webkit-scrollbar {
            width: 14px;
            height: 14px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $actionActive;
            border-radius: 9999px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }

        .search-accordion {
            margin: 0px !important;
            .search-accordion-header {
                height: 56px;
                min-height: 0px !important;
                opacity: 1 !important;
                padding-left: 24px;

                .see-all-button {
                    text-transform: none;
                    background-color: $grey-300;
                    color: $textPrimary;
                    height: 24px;
                }
            }
    
            .search-accordion-content {
                padding: 0px;
                display: block;
    
                .search-result-item {
                    text-decoration: none;
                    display: block;
                    
                    .search-result-list--item-box {
                        min-height: 56px;
                        padding: 8px 24px;
                        border-bottom: 1px solid #DADADA;
                        box-sizing: border-box;

                        &:hover {
                            background-color: $actionHover;

                            .result-index {
                                display: flex;
                            }
                        }
        
                        .search-order-link {
                            overflow-wrap: anywhere;
                        }

                        .result-index {
                            display: none;
                            font-size: 12px;
                            color: $secondaryMain;
                        }
                    }
                }
            }

            &.selected, &.recent  {
                .search-accordion-header {
                    .accordion-icon {
                        display: none;
                    }
                }
            }

            &.selected {
                .search-result-list--item-box {
                    padding-left: 8px !important;
                }
            }
        }
    }
    
    .no-orders-found{
        background-color: white;
        padding: 12px 16px;
        border-radius: 8px;
    }

    .more-record-banner {
        background-color: $infoLightBg;
        padding: 10px 16px;
        border-radius: 8px;

        p {
            letter-spacing: -0.1px;
            line-height: 1.5;
        }
    }
}

.header-profile-section {
    .search-icon {
        //padding: 12px 7px;
     &:hover{
        color: #90C928;
        }
    }
    padding-bottom: 10px;
    @include xs() {
        position: sticky;
        top: 0;
        z-index: 997;
        background-color: $disabled;
        padding-bottom: 24px;
    }
    @include sm() {
        position: sticky;
        top: 0;
        z-index: 997;
        background-color: $disabled;
    }
    .header-profile-first-row {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .global-search{
            background-color: white;
            border-radius: 8px;
           @include sm() {
             display: none;
           }
           @include xs() {
             display: none;
           }

           .search-button {
                &:hover {
                    background-color: white;
                }
           }
        }

        .header-profile-first-colm {
            display: flex;
            align-items: center;
            .header-profile-name {
                display: inline-block;
                text-align: right;
                @include xs() {
                    display: none;
                }
            }
            .header-profile-icon {
                i{
                    color: $secondaryMain;

                    &:hover {
                        color: $primaryMain;
                    }
                }
                background-color: $secondaryContrastText;
                border-radius: 8px;
                width: 44px;
                height: 44px;
                @include xs() {
                    display: none;
                }
                .icon {
                    font-size: 20px;
                }
                
            }
            .header-profile-icon-image {
                padding-left: 5px;
                padding-right: 5px;
                @include xs() {
                    display: none;
                }
            }
            .header-short-button {
                height: 44px;
                width: 44px;
                border-radius: 8px;
                background: $infoContrastText;
                border: 0;
                @include md() {
                    display: none;
                }
                @include sm() {
                    display: none;
                }
                @include lg() {
                    display: none;
                }
                @include xl() {
                    display: none;
                }
            }
            .avatar-button {
                &:hover {
                    background-color: transparent;
                }
                
                &.menu-button {
                    @include xl() {
                        display: none;
                    }
                    @include lg() {
                        display: none;
                    }
                    @include md() {
                        display: none;
                    }
                    @include sm() {
                        display: none;
                    }
                }
                &.user-profile {
                    @include xs() {
                        display: none;
                    }
                }
                .avatar-icon {
                    background-color: $secondaryMain;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 100%;
                    border: 2px solid transparent;
                    &:hover {
                        border: 2px solid $primaryMain;
                    }
                }
            }
        }
    }
    .searchbar-active{
        display: none;
        @include xs(){
            display: block;
            background-color: white;
        }
        @include sm(){
            display: block;
            background-color: white;
        }
    }
    
    .search-icon-mob{
        margin-left : 50px;
        padding: 12px;
        font-size: 19px;
        height: 20px;
        background-color: white;
        border-radius: 8px;
        .ri-lg:hover{color: $successMain;}
        display: none;
        @include xs(){
            display: block;
        }
        @include sm(){
            display: block;
        }
    }
    
}

.profile-menu {
    padding: 8px 0px;
    width: 181px;
    border-radius: 8px !important;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.08) !important;
    
}

.profile-menu-bottom {
    width: 100%;

    .mobile-notification {
        width: 100%;
        height: 64px;
        .icon {
            padding: 0px 12px 0px 0px;
            color: $secondaryMain;
        }
        .button {
            color: $secondaryMain;
        }
        &:hover {
            background-color: $actionHover;
        }
    }
}





    

