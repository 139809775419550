@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

.unauthorised-access {
    @include xl() {
        padding: 0px 0px 0px 790px;
    }
    text-align: center;  
    height: 0px;   
    img {
        &.primary-connect-logo {
            height: 40px;
            // width: 271px;
            padding: 32px 0px;
            @include xl() {
                padding: 50px;
                transform: scale(2.5);
            }
        }
        &.in-page-error{
            padding: 40px 0px;
            width: 280px;
            height: 200px;
            @include xl() {
                padding: 245px 0px 190px;
                transform: scale(2);
            }
            
        }
    }
    .statement{
        @include xl() {
            transform: scale(2.5);
        }
    }
    .statement-start {
        padding: 16px 0px;
        a{
            color: $secondaryMain;
        }
        @include xl() {
            transform: scale(2);
            padding:60px;
        }
    }
    .statement-end{
        padding: 60px 0px 32px;
        color: $primaryContrastText;
        a{
            color: $primaryContrastText;
        }
        @include xl() {
            padding: 200px 0px 28px;
            transform: scale(2.5);
        }
    }    
}
