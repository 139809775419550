@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

body {
    margin: 0;
    background-color: #ECEFF1;
}
.app-container {
    position: relative;
    @include md(){
        padding-left: 64px;
    }
    @include lg(){
        padding-left: 64px;
    }
    @include xl(){
        padding-left: 280px;
    }

    &.no-padding {
        padding: 0 !important;
    }
}
.nav-container {
    top: 64px;
}
.text-transform-none {
    text-transform: none !important;
}

.warning-button {
    background-color: $warningMain !important;
    color: $textPrimary !important;
}

.version-notification-message {
    position: fixed;
    bottom: 0px;
    right: 0px;
    text-shadow: 1px 1px 1px white;
    color: black;
    z-index: 9999;
    font-size: 13px;
}

.maintenance-override-message {
    position: fixed;
    bottom: 55px;
    right: -35px;
    text-shadow: 1px 1px 1px white;
    color: red;
    z-index: 9999;
    font-weight: 500;
    font-size: 13px;
    transform: rotate(-90deg);


    .gear-icon {
        position: absolute;
        top: 10px;
        width: 12px;
        height: 12px;
        margin:-6px 0 0 -6px;
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;
    }
    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }
}