@import "~csp-common-ui/dist/styles/media-query.scss";

.carrier-customer-not-mapped-modal {
    .modal-border-top {
        box-shadow: 0px 0.5px 0px 0px #607D8B3B inset;;
    }

    .action-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include xs() {
            justify-content: stretch;
        }

        .success-button {
            padding-left: 72px !important;
            padding-right: 72px !important;
    
            @include xs() {
                width: 100%;
            }
        }
    }
}