@import "~csp-common-ui/dist/styles/color.scss";

.tb-success-alert{
    height: 160px;
    width: 564px;
    border-radius: 0px;
    padding: 16px;

    
    .success-icon{
        color: $primaryMain;
    }
    .close-icon{
        font-size: 25px;
        color: $closeIcon;
        margin-bottom: 120px;
        margin-right: 5px;
        cursor: pointer;
    }
    .details{
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        .order-text{
            margin-top: 2px;
            color: $textPrimary;
            font-weight: 600;
        }
        .link{
            color: $secondaryMain;
        }
    }
}

